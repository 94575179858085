import Vue from 'vue'
import VueRouter from 'vue-router'
import { default as SevenRoutes } from '../2021/router/index' //'@/2021/router/index.js'
import { default as EightRoutes } from './route_2022_sz'
import { default as NineRoutes } from './route_2022_sh'
import { default as TenRoutes } from './route_2023_sz'
// import { default as ElevenRoutes } from './route_2024_sz'
import { default as BaseRoutes } from './route_base'
import { default as MoreRoutes } from './routes'
import global from '@/Global.vue'
import { id_arr } from "@/utils/api.js"
import _ from 'lodash'  // 引入lodash 处理多重嵌套的数据类型

Vue.use(VueRouter)

let reYear = '2022'
let limitYear = '2024'
let reYears = {}
let defineRoutes = []
// console.log('id_s', id_arr)
for (let y of Object.keys(id_arr)) {
  // 年份处理
  let yearFlag = (y && y.slice(0,4)) || ''
  if (yearFlag && yearFlag >= reYear) {
    if (!Object.keys(reYears).includes(yearFlag)) {
      // reYears[yearFlag] = y
      reYears[yearFlag] = [y]
    } else if (!reYears[yearFlag].includes(y)) {
      reYears[yearFlag].push(y)
    }
  }
  // >=2024 同一文件延伸多个年份的路由
  if (yearFlag && yearFlag >= limitYear) {
    for (let item of MoreRoutes) {
      defineRoutes.push({
        path: `/${y}${item.path}`,
        name: item.name + y,
        // component: item.component
      })
      let index = defineRoutes.length - 1
      for (let key of Object.keys(item)) {
        if (!defineRoutes[index][key]) {
          defineRoutes[index][key] = item[key]
        }
      }
    }
  }
}
// 部分需要重定向处理的路径
let redirectRoutes = [ { path: '/', redirect: '/home' } ]
for (let y of Object.keys(reYears)) {
  let rYearFlag = reYears[y] || ''
  if (rYearFlag && rYearFlag.length > 0) {
    redirectRoutes.push({ path: `/${y}/*`, redirect: to => {
      return { path: `${to.path.replace(`${y}/`, `${rYearFlag[0]}/`)}` };
    } });
    redirectRoutes.push({path: `/${y}`, redirect: `/${rYearFlag[0]}`});
    for (let ry of rYearFlag) {
      redirectRoutes.push({path: `/${ry}`, redirect: `/${ry}/home`});
    }
  }
}
// console.log('redirectRoutes ', reYears, redirectRoutes);

const routes = [
    // // { path: '/', alias: '/home' },
    // { path: '/', redirect: '/home' },
    // { path: '/2024/*', redirect: to => {
    //   return { path: `${to.path.replace('2024/', '2024sz/')}` };
    // } },
    // { path: '/2023/*', redirect: to => {
    //   return { path: `${to.path.replace('2023/', '2023sz/')}` };
    // } },
    // { path: '/2022/*', redirect: to => {
    //   return { path: `${to.path.replace('2022/', '2022sh/')}` };
    // } },
    // { path: '/2024sz', redirect: '/2024sz/home' },
    // { path: '/2023sz', redirect: '/2023sz/home' },
    // { path: '/2022sh', redirect: '/2022sh/home' },
    // { path: '/2022sz', redirect: '/2022sz/home' },
    ...redirectRoutes,
    ...defineRoutes, // >= 2024
    // ...MoreRoutes, // >= 2025
    // ...ElevenRoutes, // 2024 广东深圳
    ...TenRoutes, // 2023 广东深圳 
    ...NineRoutes, // 2022 上海 
    ...EightRoutes, // 2022 广州深圳 
    ...SevenRoutes, // 2021 
    ...BaseRoutes, // base
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (from.meta.keepAlive) {
                from.meta.savedPosition = document.body.scrollTop
            }
            return {
                x: 0,
                y: to.meta.savedPosition || 0
            }
        }
    }
})

router.beforeEach((to, from, next) => {
  // console.log(process.env, global.year, reYears);
  // console.log(to, from);
  // console.log({path:to.path, fullPath:to.fullPath, name:to.name, query:_.cloneDeep(to.query), params:_.cloneDeep(to.params)}, {path:from.path, fullPath:from.fullPath, name:from.name, query:_.cloneDeep(from.query), params:_.cloneDeep(from.params)});
  // 移除head中部分标签
  if (to.meta && to.meta.isbase) {
    let netDomM = $(`link[src*='/md5.']`);
    let netDomL = $(`link[href*='player.polyv.net']`);
    let netDomS = $(`script[src*='player.polyv.net']`);
    netDomL.remove()
    netDomS.remove()
    netDomM.remove()
  }
  /* year重定向 - 无年份的链接重定向到对应有年份的路径
   * 无需year重定向或已完成year重定向 则开始设置浏览器标签页title、qd参数 */
  if (to.path.split('/').length < 3 && !(to.meta&&to.meta.isbase) && !(to.path.includes('.js') || to.path.includes('.json'))) {
    next({
      path: `/${global.year}${to.path}`,
      query: { ...to.query },
      replace: true
    })
  } else {
    /* 浏览器标签页title */
    if (to.meta.title) {
      document.title = to.meta.title
    } else {
      document.title = `GIAC 全球互联网架构大会`;
      // let year = to.path.split('/')[1];
      // document.title = `GIAC ${id_arr[year].year} 全球互联网架构大会 ${id_arr[year].address}站`;
    }
    /* qd参数(需要一直在路由中保留的参数) */
    let nextTo = { 
      path: to.path, 
      fullPath: to.fullPath, 
      name: to.name, 
      query: _.cloneDeep(to.query), 
      params: _.cloneDeep(to.params), // 深拷贝，但无法直接拷贝Symbol类型
    };
    let fromQD = from && from.query && from.query.qd && from.query.qd;
    let hashQD = global.hashParam && global.hashParam['qd'] && global.hashParam['qd'];
    let nextQD = nextTo.query.qd || fromQD || hashQD || false;
    if(nextQD && (nextQD !== nextTo.query.qd)) {
      nextTo.query.qd = nextQD;
      return next({ ...nextTo });
    }
    // return next();
  }
  next();
})

export default router